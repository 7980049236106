import React from 'react';
import cx from 'classnames';

import styles from './Bio.module.scss';
import { Page } from '../Page/Page';

export const Bio = () => {
  return (
    <Page>
      <div className={styles.container}>
        <div className={styles.bioWrapper}>
          <p>
            {emphasised('inneruu')} is a collective working at the intersections between band culture, visual installations, and experimental instruments.
          </p>
          <p>
            They transform unique spaces into a place of childlike wonder by experimenting with different interactive instruments and spatial projections, while maintaining a focus on song writing.
          </p>
          <div className={styles.pressContainer}>
            <PressQuote
              quote="Een amuse die alle zintuigen prikkelt"
              source="Amuse Festival"
            />
            <PressQuote
              quote="Noorderlicht straalt op ontdekkingsreizigers van een nieuwe wereld"
              source="3voor12 Leiden"
              link="https://3voor12.vpro.nl/lokaal/leiden/artikelen/overzicht/2022/Maart/Noorderlicht-straalt-op-ontdekkingsreizigers-van-een-nieuwe-wereld-.html"
            />
          </div>
        </div>
      </div>
    </Page>
  );
};

const emphasised = (text: string) => (
  <span className={styles.bandName}>{text}</span>
);

interface PressQuoteProps {
  quote: string;
  source: string;
  link?: string;
}

const PressQuote = ({ quote, source, link }: PressQuoteProps) => (
  <div className={styles.pressQuote}>
    "{quote}"
    <br />
    <a className={cx({ [styles.quoteWithLink]: !!link })} href={link}>
      <span className={styles.source}>{source}</span>
    </a>
  </div>
);
